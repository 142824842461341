"use client";

import { getURL } from "@/utils/get-url";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { createClient } from "@v1/supabase/client";
import { Button } from "@v1/ui/button";

function GoogleSigninContent() {
  const supabase = createClient();

  const handleSignin = () => {
    try {
      supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${getURL()}api/auth/callback`,
        },
      });
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      throw error; // Let Sentry catch this
    }
  };

  return (
    <Button
      onClick={handleSignin}
      variant="secondary"
      className="font-mono w-full"
      type="button"
    >
      Sign in with Google
    </Button>
  );
}

export function GoogleSignin() {
  return (
    <SentryErrorBoundary
      fallback={<div>An error occurred in the GoogleSignin component.</div>}
    >
      <GoogleSigninContent />
    </SentryErrorBoundary>
  );
}
